<template>
  <div class="pancarte-container">
    <Pancarte1 v-if="pancarte === 0" class="pancarte"/>
    <Pancarte2 v-if="pancarte === 1" class="pancarte"/>

    <Horloge1 v-if="horloge === 0" class="horloge"/>
    <Horloge2 v-if="horloge === 1" class="horloge"/>
    <Chrono1 v-if="horloge === 2" class="horloge"/>
    <Chrono2 v-if="horloge === 3" class="horloge"/>

    <div class="compteur-container">
      <Nombres :n="n" class="compteur"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pancarte-container {
  position: relative;
  width: 200px;
  height: 171px;

  .pancarte {
    display: block;
    position: absolute;
    width: 100%;

    top: 0;
    left: 0;
  }

  .horloge {
    display: block;
    position: absolute;
    height: 42%;

    left: 6%;
    top: 47%;
  }

  .compteur-container {
    display: block;
    position: absolute;

    text-align: center;

    height: 54%;
    width: 50%;

    right: 5%;
    bottom: 4%;

    padding-top: 10%;
    padding-bottom: 10%;

    .compteur {
      display: inline-block;
      height: 100%;
    }
  }
}
</style>

<script>
import Horloge1 from '@/resources/images/inline.horloge1.svg';
import Horloge2 from '@/resources/images/inline.horloge2.svg';
import Chrono1 from '@/resources/images/inline.chrono1.svg';
import Chrono2 from '@/resources/images/inline.chrono2.svg';
import Pancarte1 from '@/resources/images/inline.pancarte_1.svg';
import Pancarte2 from '@/resources/images/inline.pancarte_2.svg';

export default {
  name: 'PancarteCountdown',

  data() {
    return {
      pancarte: this.randBetween(0, 1),
      horloge: this.randBetween(0, 3),
    };
  },

  components: {
    Pancarte1,
    Pancarte2,
    Horloge1,
    Horloge2,
    Chrono1,
    Chrono2,
  },

  props: ['n'],
}
</script>
